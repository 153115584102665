<template>
    <div>
        <el-dialog title="批量修改广告组像素" :visible="editPixelShow" width="600px" @close="close" @open="openInit">
            <div v-if="editPixelShow">
                <el-form :model="adName" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="选择像素：">
                        <el-select v-model="adName.pixelId" placeholder="请选择像素" style="width:90%">
                            <el-option :label="item.name" :value="item.pixel_id" v-for="(item,index) in pixelList" :key="index"></el-option>
                        </el-select>
                        <el-tooltip class="item" effect="dark" content="强制刷新主页，清除本地缓存主页数据" placement="top">
                            <el-button icon="el-icon-refresh" @click="getPixelList(true)" type="text" size="mini" :disabled="loadPixel"></el-button>
                        </el-tooltip>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="sure" id="batchEditPixelEvent">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import { mutateAdsetPixel} from "@/api/adManagement";
    import {accountPixel} from '@/api/creatAd.js'
    export default {
        props:['editPixelShow','nameLevel','list'],
        data() {
            return {
                adName:{
                    pixelId:''
                },
                pixelList:[],
                loadPixel:false
            }
        },
        computed:{
            accountId(){
                if(this.list.length && this.list[0].rowAccountId){
                    return this.list[0].rowAccountId
                }else{
                    return this.$store.getters.accountId 
                }
            }
        },
        mounted() {
            
        },
        methods: {
            close(){
                this.adName.pixelId = '';
                this.$emit('close','editPixelShow')
            },
            openInit(){
                this.$showLoading();
                this.getPixelList()
            },
            getPixelList(v){
                this.loadPixel = true;
                accountPixel({accountId:this.accountId},this.list[0].rowThirdNum).then(res=>{
                    // console.log(res);
                    this.loadPixel = false
                    this.$hideLoading()
                    this.pixelList = res.data;
                    if(v){
                        this.$message('更新成功')
                    }
                });
            },
            sure(){
                if(!this.adName.pixelId){
                    this.$message({
                        type:'warning',
                        message:'像素不能为空'
                    })
                }
                let ids = this.list.map(v=>v.id);
                this.$showLoading();
                mutateAdsetPixel({ids:ids,pixelId:this.adName.pixelId,accountId:this.accountId}).then(res=>{
                    this.$hideLoading()
                    if(res.code == 0){
                        this.$message({
                            type:'success',
                            message:'保存成功'
                        })
                        this.$emit('get-list')
                        this.close()
                    }
                })
            }
        },
    }
</script>
<style lang="scss">
</style>