
<template>
    <div>
        <div class="tabWrap">
            <span>数据展示</span>
            <div class="dataTab">
                <div class="tabsList">
                    <p @click="setIndex(1)" :class="index == 1?'active':''">广告数据</p>
                    <p @click="setIndex(2)" :class="index == 2?'active':''">经营分析</p>
                    <p @click="setIndex(3)" :class="index == 3?'active':''">临界值</p>
                </div>
            </div>
        </div>
        <div class="tabCon">
            <div class="tabItem" v-show="index == 1">
                <el-table :data="tableData">
                    <el-table-column v-for="item in cols1" :label="item.name" :prop="item.key"></el-table-column>
                </el-table>
            </div>
            <div class="tabItem" v-show="index == 2">
                <el-table :data="tableData">
                    <el-table-column v-for="item in cols2" :label="item.name" :prop="item.key" :width="item.width">
                        <template slot-scope="scope">
                            <div v-if="item.key == 'profitRateResult'">
                                <img :src="imgSrc[scope.row.profitRateResult]" alt="" v-if="scope.row.profitRateResult" />
                                <span v-else>--</span>
                            </div>
                            <div v-else>{{ scope.row[item.key] || '--'}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="tabItem" v-show="index == 3">
                <el-table :data="tableData">
                    <el-table-column v-for="item in cols3" :label="item.name" :prop="item.key"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
import HIGHT from '@/assets/icon/level1.png';
import MID from '@/assets/icon/level2.png';
import LOW from '@/assets/icon/level3.png';
import LOW2 from '@/assets/icon/level4.png';
    export default {
        props:{
            multiData:{
                type:Object,
                default:()=>{}
            }
        },
        data(){
            return {
                index:1,
                cols1:[{name:'时间',key:'date',width:'120'},{name:'总花费',key:'spend'},{name:'订单量',key:'orderCount'},{name:'订单成本',key:'avgAdCostOrigin'},{name:'毛利(%)',key:'grossMarginRate'},{name:'加购数',key:'addInfos'},{name:'加购成本',key:'addInfoCostOrigin'}],
                cols2:[{name:'时间',key:'date',width:'120'},{name:'签收率(%)',key:'signRate'},{name:'采购占比(%)',key:'logisticsRate'},{name:'运费占比(%)',key:'buyRate'},{name:'广告费占比(%)',key:'adRate'},{name:'三项成本占比(%)',key:'threeRate'},{name:'经营评估',key:'profitRateResult'}],
                cols3:[{name:'时间',key:'date',width:'120'},{name:'客单价',key:'amountPerCustomer'},{name:'客单量',key:'countPerCustomer'},{name:'ROI',key:'roi'},{name:'ROI临界点',key:'lowestRoi'},{name:'平均广告费',key:'avgAdCost'},{name:'广告临界值',key:'adCriticalVal'}],
                imgSrc: {
                    HIGHT,
                    MID,
                    LOW,
                    LOW2,
                },
            }
        },
        computed:{
            tableData(){
                let list = [];
                list.push(this.getItem());
                list.push(this.getItem('_1d'));
                list.push(this.getItem('_3d'));
                list.push(this.getItem('_7d'));
                list.push(this.getItem('_nd'));
                return list
            }
        },
        methods:{
            setIndex(v){
                this.index = v
            },
            getItem(str){
                console.log(this.multiData);
                if(!this.multiData) return {}
                let keys = Object.keys(this.multiData);
                let obj = {}
                if(str == '_1d'){
                    obj.date = '昨日'
                }else if(str == '_3d'){
                    obj.date = '过去3天'
                }else if(str == '_7d'){
                    obj.date = '过去7天'
                }else if(str == '_nd'){
                    obj.date = '生命周期（不含今天）'
                }else{
                    obj.date = '当日'
                }
                if(str){
                    let curKeys = keys.filter(v=>v.indexOf(str)>-1);
                    curKeys.forEach(item=>{
                        obj[item.slice(0,-3)] = this.multiData[item]
                    })
                }else{
                    let curKeys = keys.filter(v=>v.indexOf('_')==-1);
                    curKeys.forEach(item=>{
                        obj[item] = this.multiData[item]
                    })
                }
                return obj
            }
        }
    }
</script>
<style lang="scss" scoped>
.tabItem{
    font-size: 12px;
    img{
        width: 20px;
        height: 20px;
    }
}
.el-table{
    font-size: 12px;
}
.tabWrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .tabsList{
        display: flex;
        align-items: center;
        background: #efefef;
        height: 28px;
        border-radius: 14px;
        p{
            line-height: 28px;
            padding: 0 20px;
            border-radius: 14px;
            cursor: pointer;
            &.active{
                background: #fff;
                border-radius: 14px;
                color: #409EFF;
            }
        }
    }
}
</style>