import { render, staticRenderFns } from "./dataBoard.vue?vue&type=template&id=6046e6cc&scoped=true"
import script from "./dataBoard.vue?vue&type=script&lang=js"
export * from "./dataBoard.vue?vue&type=script&lang=js"
import style0 from "./dataBoard.vue?vue&type=style&index=0&id=6046e6cc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6046e6cc",
  null
  
)

export default component.exports