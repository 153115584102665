<template>
    <div>
        <el-drawer title="批量编辑广告"  :visible="batchEditAds" direction="rtl" @close="close" wrapperClosable size="1250px" :append-to-body="true">
            <div class="drawerCon editAdsCon"  v-loading="loading">
                <el-form label-width="150px" style="margin-left:20px">
                    <el-form-item label="选择广告：">
                        <el-select v-model="id" @change="selectAd">
                            <el-option :label="ad.name+'#'+ad.id" :value="ad.id" v-for="ad in list" :key="ad.id">{{ad.name}}#{{ad.id}}</el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <ads :accountId="accountId" :initData="initData" @setAds="setAds" :edit="true" :adsInfo="adsInfo"></ads>
            </div>
            <div class="demo-drawer__footer">
                <p></p>
                <div>
                    <el-button type="primary" @click="sure">保存</el-button>
                </div>
                <p></p>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import ads from '../../createAd/components/ads';
import {adForUpdate,updateAd,accountPixel} from '@/api/creatAd.js'
import {adConstsMap} from '@/api/creatAd.js'
import {mutateAds} from '@/api/adManagement.js'
    export default {
        props:['list','batchEditAds','initData'],
        data () {
            return {
                accountId:this.$store.getters.accountId,
                adsMsg:{},
                loading:false,
                draftCampaignId:null,
                adsInfo:null,
                type:'FB',
                id:null,
            }
        },
        components:{
            ads
        },
        methods: {
            close(){
                this.$emit('close','batchEditAds')
            },
            setAds(v){
                this.adsMsg = v;
            },
            selectAd(v){
                this.id = v;
                this.getAdInitData();
            },
            getAdInitData(){
                // console.log(this.id);
                let params = {
                    level:'ad',
                    type:this.type
                }
                params.accountId = this.accountId
                params.fbId = this.id;
                this.loading = true;
                adForUpdate(params).then(res=>{
                    this.loading = false;
                    this.draftCampaignId = res.data.draftCampaignId;
                    this.adsInfo = res.data.adcreative
                    this.adsInfo.promoteUrl = res.data.promoteUrl;
                    this.adsInfo.fbPixelId = res.data.fbPixelId;
                })
            },
            // 点击确定
            sure(){
                let params = {accountId:this.accountId,adIds:this.list.map(v=>v.id),fbCreative:this.adsMsg[0]}
                mutateAds(params).then(res=>{
                    // console.log(res);
                    if(res.code == 0){
                        this.$message({
                            type:'success',
                            message:'修改成功'
                        })
                        this.close();
                    }
                })
            }
        },
        watch:{
            list:{
                handler(){
                    // console.log(this.list[0]);
                    if(this.list.length){
                        this.id = this.list[0].id;
                    }
                },
                deep:true
            },
            batchEditAds(){
                if(this.batchEditAds){
                    this.getAdInitData();
                }
                
            }
        },
        mounted() {
            // this.getInitData();
            
        },
    }
</script>
<style lang="scss" scope>
    
</style>